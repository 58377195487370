import {Injectable} from '@angular/core';
import {ParentService} from "./ParentService";
import {ExamEntity} from "../model/ExamEntity";
import {Shared} from "../view/shared/shared";
import {EstimationSettingsEntity} from "../model/EstimationSettingEntity";

@Injectable()
export class ExamService extends ParentService{

  public getExams(){
    return this.http.get<ExamEntity[]>(this.shared.MAGNAMENT_URL+'/getExams');
  }

  public saveExam(exam: ExamEntity){
    return this.http.post<ExamEntity>(this.shared.MAGNAMENT_URL+'/saveExam', exam);
  }

  public getEstimationSettings(examId){
    return this.http.get<EstimationSettingsEntity[]>(this.shared.MAGNAMENT_URL+'/getEstimationSettings/'+examId);
  }

  public getPosition(examId, score, exp, mu, sigma, population, handicap, tailChartStart, tailChartMiddle, tailChartPositions){
    return this.http.get<number>(this.shared.MAGNAMENT_URL+'/getEstimation/'+examId+'/'+score+'/'+exp+'/'+mu+'/'+sigma+'/'+population+'/'+handicap+'/'+tailChartStart+'/'+tailChartMiddle+'/'+tailChartPositions);
  }

  public getOtherYears(year, category){
    return this.http.get<number[]>(this.shared.MAGNAMENT_URL+'/getOtherYears/'+year+'/'+category);
  }

  public saveEstimation(estimation: EstimationSettingsEntity){
    return this.http.post<EstimationSettingsEntity>(this.shared.MAGNAMENT_URL+'/saveEstimation', estimation);
  }

  public reCorrectExams(examId){
    return this.http.get<boolean>(this.shared.MAGNAMENT_URL+'/recorrectExams/'+examId);
  }

  public reEstimateExams(examId){
    return this.http.get<boolean>(this.shared.MAGNAMENT_URL+'/reestimateExams/'+examId);
  }



}
